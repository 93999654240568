import NextLink from "next/link";
import { Image, Box, Button, Flex, Text, HStack, VStack, Heading, List, ListItem, Divider, Grid, GridItem, Link } from "@chakra-ui/react";
import Header from "@/app/layout/global/header";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalDataContext } from "@/app/context/global/globalDataContext";
import { FriendsContext } from "@/app/context/friends/friendsContext";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { GameFriendsScore } from "@/app/context/friends/layout/gameFriendsScore";
import { HiOutlineArrowCircleDown } from 'react-icons/hi';
import { useTranslation } from "next-i18next";
import Footer from "@/app/layout/global/footer";
import MenuContent from "../global/menuContent";
import Skeleton from "@/app/layout/global/skeleton";
import { BodyHead } from "../global/bodyHead";
import { buildGameUrl } from "@/app/helpers/buildUrlHelper";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook, FaSpaghettiMonsterFlying } from "react-icons/fa6";

export default function HomeLayout() {
    
  const {t} = useTranslation();
  const {
    language,
    isInWebview
} = useContext(GlobalDataContext)

const {
    friendRequestWithUser,
    setShowModalInviteFriends
} = useContext(FriendsContext)

const {
    user,
} = useContext(AuthenticationContext)

const {
  character
} = useContext(NotificationContext)

  const scrollContainerRef = useRef<HTMLDivElement|null>(null);
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    if (scrollContainerRef.current && scrollContainerRef.current.scrollTop > 0) {
      setIsVisible(false);
    }else{
      setIsVisible(true);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer?.addEventListener('scroll', handleScroll);
    return () => {
      scrollContainer?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <BodyHead
       url={process.env.NEXT_PUBLIC_URL as string}
       mainTitle={t("home.title")}
       title={t("home.titleBody")}
       description={t("home.descriptionBody")}
    />
    <Skeleton
      background={<Flex w="full" h="10vh" position="absolute" top="0" left="0" background="linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))"></Flex>}
      header={<Header/>}
      footer={<Footer>
          <HStack alignSelf={"center"}>
            <Link as={NextLink} href={buildGameUrl(language,1)} alignSelf={"center"}>
              <Button background="#f16529!important" color="white!important" mb="5" variant='solid' size="lg" borderRadius="50px">
                {t("menu.myGooblies").toUpperCase()}
              </Button>
            </Link>
          </HStack>
          {(isVisible ) && <Flex w="full" placeContent={"center"}>
            <Link href="#explanation">
              <HiOutlineArrowCircleDown className="vertical-shake" color="white" fontSize={"34px"}/>
            </Link>
          </Flex> }
      </Footer>}
      menu={<MenuContent/>}
      friends={<>{user && user.email && <GameFriendsScore friends={friendRequestWithUser}/> }</>}
      scrollContainerRef={scrollContainerRef}
    >
      <>
        <Flex w="100%" h="100vh" minH="100vh" grow={0} shrink={0} position="relative" backgroundSize="cover!important" backgroundPosition="center" background={character?.background? ("url("+character.background+")") : "url(/images/ana.jpeg)"}></Flex>  
        <Flex
            w="full"    
            pb={"25%"}                                       
        >      
          <VStack
              alignItems="start"  
              w="full"
              m="auto"
              color={"white"}
              >
                <div id="explanation"></div>
                <Box p={5} pt={"15%"}>                      
                  <Heading size="lg">{t("home.howToPlay")}</Heading>
                  <Text >{t("home.description")}</Text>
                  {!isInWebview && process.env.NEXT_PUBLIC_GOOGLE_STORE_URL && <Link href={process.env.NEXT_PUBLIC_GOOGLE_STORE_URL} isExternal>                    
                      <Image src="/images/download-android.png" pt={3} alt="Android" width="150px" />                     
                  </Link>}
                  <Heading size="md" pt={6}>{t("home.exploreMode")}</Heading>
                  <List spacing={1} py={2}>
                      <ListItem>{t("home.chase1")}</ListItem>
                      <ListItem>{t("home.chase2")}</ListItem>
                      <ListItem>{t("home.chase3")}</ListItem>
                      <ListItem>{t("home.chase4")}</ListItem>  
                  </List>
                  <Heading size="md" pt={4}>{t("home.questMode")}</Heading>
                  <List spacing={1} py={2}>
                      <ListItem>{t("home.quest1")}</ListItem>
                      <ListItem>{t("home.quest2")}</ListItem>
                      <ListItem>{t("home.quest3")}</ListItem>
                      <ListItem>{t("home.quest5")}</ListItem>  
                      <ListItem>{t("home.quest4")}</ListItem>  
                  </List>
                  <Heading size="md"pt={4}>{t("home.houseMode")}</Heading>
                  <List spacing={1} py={2}>
                      <ListItem>{t("home.house1")}</ListItem>
                      <ListItem>{t("home.house2")}</ListItem>
                      <ListItem>{t("home.house3")}</ListItem>
                      <ListItem>{t("home.house4")}</ListItem>  
                      <ListItem>{t("home.house5")}</ListItem>  
                  </List>
                  <Divider borderColor={"white"} pt={4} opacity={0.1}/>
                  <Grid background="#d83a41" color="white" borderRadius={4} templateColumns='repeat(1, 1fr)' gap={1} py={3}fontSize={"xs"} fontWeight={"bold"}>
                      <GridItem textAlign={"center"}>🧒 {t("home.5yearsAndMore")}</GridItem>
                  </Grid>                    
                  <Text pt={6}>{t("home.convinceMessage")}</Text>
                  <Flex py={6} flexDirection={"column"}>
                      <Text>{t("home.followUs")}</Text>
                      <HStack>
                        <Link target="_blank" display="inline-flex" p={2} px={2} fontSize={"2em"} href={process.env.NEXT_PUBLIC_FACEBOOK_URL}><FaFacebook /></Link>
                        <Link target="_blank" display="inline-flex" p={2} px={2} pr={0} fontSize={"2em"} href={process.env.NEXT_PUBLIC_INSTAGRAM_URL}><RiInstagramFill /></Link>                  
                      </HStack>
                  </Flex>
                </Box>
          </VStack>    
        </Flex>  
      </>
    </Skeleton>
    </>
  )
}