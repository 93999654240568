import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { UserConfig } from "next-i18next";
import { GetServerSideProps } from "next";
import nextI18NextConfig from "@/../next-i18next.config";
import HomeLayout from "@/app/layout/pages/homeLayout";

export default function Home() {
    return (<HomeLayout/>)  
}

export const getServerSideProps: GetServerSideProps<{    

}> = async (context: any) => {
  const {language} = context.query

  return { 
      props: { 
          ...(
              await serverSideTranslations(
                  language ?? "en",
                  ['common'],
                  nextI18NextConfig as UserConfig
              )
          ),             
      } 
  }

}